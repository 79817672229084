<template>
  <div>
    <section class="container-fluid mb-section mt-10">
      <v-row class="d-flex justify-space-around">
        <v-col
          cols="11"
          md="6"
          class="d-flex justify-center align-center mx-auto"
        >
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              title="As Quitandas têm gosto de memória"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p>
                  Sabe aquele biscoito caseiro que você come e fala sem pensar:
                  “huum… tem gosto de casa de vó!”? Pois bem, essa é a quitanda
                  mineira. Presentes em todo o estado de Minas Gerais, as
                  quitandas são “as comedorias ligeiras” (BONOMO, 2013,
                  s/p)<sup>1</sup>, a “pastelaria caseira, o biscoito, a broa, a
                  rosca, o sequilho, o bolo” (FRIEIRO, 1982 apud BONOMO, 2013,
                  s/p), tradicionalmente confeccionadas por mulheres.
                </p>
                <p>
                  A produção de alimentos saudáveis, o saber e a memória que as
                  quitandas representam às famílias mineiras são vertentes que
                  compõem o entendimento da prática da Soberania e Segurança
                  Alimentar e Nutricional (BRASIL, 2014). Um povo ou uma
                  comunidade que mantém suas tradições culturais por meio da
                  prática alimentar contrapõe investidas dos impérios
                  alimentares (super/hipermercados, indústria de alimentos
                  ultraprocessados).
                </p>
                <p>
                  O fazer quitanda é resistência, sabedoria e garantia alimentar
                  e nutricional de um modo de reprodução social interrompido ou
                  extinto para as famílias atingidas pelo desastre–crime do
                  rompimento da barragem de Fundão.
                </p>
              </template>
            </Card>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-end flex-column"
        >
          <v-img
            max-width="80%"
            src="../../assets/conteudo_cultura_alimentar_13.png"
          ></v-img>
          <p
            style="text-align: center; font-size: 0.6em; max-width: 80%"
            class="mt-1"
          >
            Quitanda mineira feita com amor e afeto.<br />
            Fotos: Tainara Torres / Acervo Revista Sirene (Ed. 32, novembro de
            2018).
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid mt-10 mb-section">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="8"
          class="container d-flex justify-center justify-md-start align-center border__image3"
          style="z-index: 10;"
        >
          <p style="z-index: 15; color: white;" class="ma-0">
            "As variedades das comidas foram afetadas. Por exemplo, a gente
            tinha plantação de milho, que a gente mesmo consumia, o fubá que a
            gente fazia, a merenda que era feita com broa, angu que você mexia.
            A alimentação mudou completamente, a gente tinha uma vida saudável e
            hoje a gente consome muito mais química."<sup>2</sup>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid mt-10 mb-section">
      <v-row class="d-flex justify-space-around">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img
            max-width="100%"
            src="../../assets/conteudo_cultura_alimentar_14.png"
          ></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Quitanda mineira de Camargos. Fotos: Paula Zanardi, 2021.
          </p>
        </v-col>
        <v-col
          cols="11"
          md="6"
          class="d-flex justify-center align-center mx-auto"
        >
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A quitanda é, assim, uma representação dos hábitos alimentares
                  e manifestação sociocultural marcante em Minas Gerais, em
                  processo de registro como patrimônio imaterial pelo IPHAN e
                  IEPHA. As quitandeiras, com suas diferentes técnicas,
                  instrumentos, fazeres e saberes expressam vividamente a
                  identidade e o modo de vida de cuidar e expressar sua
                  mineiridade através do processamento da diversidade de
                  alimentos de seu entorno, muitas das vezes frutos de
                  extrativismo florestal e/ou cultivados nas pequenas hortas,
                  pomares e roças.
                </p>
                <p>
                  Transmitido de geração a geração, o modo de fazer quitandas é
                  uma ferramenta poderosa na soberania alimentar de uma
                  comunidade, povo e/ou território, já que a mesa para muitos
                  mineiros é a conexão afetiva de gerações.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid mt-10 mb-section">
      <v-row class="d-flex justify-space-between">
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img
            max-width="100%"
            src="../../assets/conteudo_cultura_alimentar_16.png"
          ></v-img>
          <p
            style="text-align: center; font-size: 0.6em; width: 80%;"
            class="mt-1"
          >
            Quitanda mineira feita com amor e afeto. Foto: Acervo Revista Sirene
            (Ed. 32, novembro de 2018).
          </p>
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="container d-flex justify-center justify-md-start align-center border__image2"
          style="z-index: 10;"
        >
          <p style="z-index: 15; color: white;" class="ma-0">
            "Eu fazia um monte de quitanda, sabe? Eu tinha aquele prazer de
            receber... Eu inventava um monte de coisas, fazia uns bolinhos e
            assava no forno de tijolo, assava broinhas, fazia pão caseiro,
            gostava de estar arrumando a casa. Hoje eu olho para os quartos
            vazios e encho os olhos d'água, não tem ninguém gente, aquela casa
            tão grande, tem dia que eu fico tão agoniada que eu fecho tudo e
            deixo só o nosso quarto e a cozinha."<sup>3</sup>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container mt-10 mb-section">
      <v-row class="d-flex justify-space-around">
        <v-col cols="11" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Os saberes transmitidos e enraizados no cotidiano das famílias
                  e comunidades têm um lugar especial: a cozinha mineira. Ela é
                  o centro da vida familiar dos mineiros, onde têm lugar as
                  reuniões familiares, as conversas importantes e também as de
                  jogar fora, o cafezinho fresco com aquela quitanda de encher o
                  coração de vida. Espaço de afeto, de desentendimentos e também
                  de entendimentos, é acolhimento. Por tudo isso, é garantia de
                  saúde física e também mental.
                </p>
                <p>
                  Seja por meio das quitandas, da produção de licores e doces de
                  frutas ou dos queijos artesanais, o saber–fazer garante às
                  famílias mineiras o acesso a comida de verdade e, junto dela,
                  seus modos de existir tradicionais recheados de saúde, afeto e
                  sentido de vida não traduzível em palavras, mas facilmente
                  sentido pelo amor contido em uma quitanda mineira feita com
                  aquela receita da vó.
                </p>
                <p style="text-align: center;">
                  <strong
                    >Autores: Isabela Fabiana da Silva Ladeira <sup>4</sup>,
                    Rafael Gustavo F. Pereira <sup>5</sup></strong
                  >
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img
            max-width="100%"
            src="../../assets/conteudo_cultura_alimentar_15.png"
          ></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Comercialização de quitandas na Feira Livre de Mariana-MG. Foto:
            Acervo Cáritas.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> “As primeiras receitas de quitandas começaram a
              surgir em Minas Gerais no final do século XVIII, quando aconteceu
              a ruralização da economia. A partir de então, as receitas passaram
              a ser transmitidas de mãe para filha, de geração a geração,
              tornando-se verdadeiros patrimônios das famílias. As quitandeiras,
              que são mulheres que fazem e vendem quitandas, como são guardiãs
              do nosso estado e, por isso, representam um bem cultural relevante
              para a memória e identidade de Minas Gerais. Trata-se, então, de
              uma atividade tradicional, cujo conhecimento é passado de geração
              em geração e que ainda mantém elementos do passado, tais como
              utilização dos ingredientes da roça, a venda por encomendas, o
              modo de fazer as receitas e os fornos de barro e a lenha. No
              entanto, trata-se também de uma atividade que não ignora as
              facilidades da modernidade, incorporando elementos como
              liquidificadores, processadores, batedeira e fornos elétricos.
              Assim, pensamos que, por ser uma referência cultural do estado de
              Minas Gerais, o ofício das quitandeiras é um patrimônio que deve
              ser preservado e valorizado” (BONOMO, 2013, s/p).
            </p>
            <p>
              <sup>2</sup> Fala da Sra. Soraia Cristina Miranda, moradora de
              Paracatu de Baixo, extraída do Jornal Sirene Ed. 61 - maio/2021.
            </p>
            <p>
              <sup>3</sup> Fala de Maria de Lourdes André Pereira, na oficina de
              Cartografia Social, realizada na Igreja Matriz do Sagrado Coração
              de Jesus, localizada no bairro Colina, em Mariana-MG, no dia 24 de
              abril de 2018.
            </p>
            <p>
              <sup>4</sup> Engenheira Agrônoma (UFV), Assessora Técnica do
              Cadastro, Etapa 2 e 3.
            </p>
            <p>
              <sup>5</sup> Engenheiro Agrônomo (UFV), Assessor Técnico do
              Cadastro, Etapa 2 e 3.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <strong>Referências Bibliográficas</strong>
            </p>
            <p>
              BONOMO, Juliana.
              <strong>Ofício das Quitandeiras de Minas Gerais</strong>. Processo
              de registro do ofício das quitandeiras (SEI 01450.01/781/2013-55),
              IPHAN: 2013.
            </p>
            <p>
              BRASIL. Ministério da Saúde.
              <strong
                >Guia Alimentar para a População Brasileira promovendo a
                alimentação saudável</strong
              >. Normas e manuais técnicos: Brasília, 2014.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center pa-4">
        <v-col class="d-flex justify-center align-center">
          <v-btn :to="{ name: 'conteudos_tematicos' }" color="#EDBD43">
            <strong>Voltar ao início de conteúdos temáticos</strong>
          </v-btn>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  components: { Card },
};
</script>

<style scoped>
.v-main__wrap {
  color: #f9f7ed !important;
}

.mb-section {
  margin-bottom: 3em;
}

.mt-section {
  margin-top: 5em;
}

a {
  text-decoration: none;
  color: black;
}

.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mt-section {
    margin-top: 1em;
  }

  .title__img {
    width: 50%;
  }
  .border__image {
    background-color: #426e51;
    max-width: 50%;
    padding: 2em;
    position: relative;
  }

  .border__image p {
    font-size: 0.6em;
    max-width: 100%;
    padding: 0 !important;
  }

  .border__image::before {
    display: none;
  }

  .border__image2 {
    font-size: 20px;
    background-color: #426e51;
    padding: 0;
    border-radius: 12px;
    text-align: center;
  }

  .border__image2 p {
    max-width: 100%;
    padding: 0 !important;
  }

  .border__image2::before {
    display: none;
  }

  .border__image3 {
    font-size: 20px;
    background-color: #426e51;
    padding: 0;
    border-radius: 12px;
    text-align: center;
  }

  .border__image3::before {
    display: none;
  }

  .border__image3 p {
    max-width: 100%;
    padding: 0 !important;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.6em;
}

.border__image {
  max-width: 50%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_cultura_alimentar_07.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 3em 2em 2em 2em;
}

.border__image2 {
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  top: 30%;
  width: 100%;
  height: 40%;
  background-image: url("../../assets/conteudo_cultura_alimentar_06.png");
  background-size: contain;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 85%;
  padding: 0 0 3em 12em;
}

.border__image3 {
  max-width: 900px;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_cultura_alimentar_07.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 3em 0 1em 11em;
}
</style>
